import { $axios } from '../index'

/**
 * @description: 根據站點Id查詢熱門搜索詞
 */
export const apiGetHotSearch = () => {
  return $axios()
    .post(`:search/app/search/hot/queryBySiteId`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 關鍵字分页搜索商品
 * @param {string} keyword  關鍵詞
 * @param {string} category  品類
 * @param {number} pageNum  頁數
 * @param {number} sort  排序：1 價格升序、2 價格降序、3 新品、4 銷量升序、5 銷量降序、6 評論數
 */
export const apiSearchGoods = (keyword = '', pageNum = 1, sort = 0, category = '') => {
  return $axios()
    .post(`:search/app/goods/sku/query`, {
      keyword,
      pageNum,
      pageSize: 10,
      category,
      sort,
    })
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 獲取新品
 */
export const apiGetNewProduct = () => {
  return $axios()
    .get(`:search/app/goods/sku/newProduct`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 獲取熱品
 */
export const apiGetHotProduct = () => {
  return $axios()
    .get(`:search/app/goods/sku/hotProduct`)
    .then((res: any) => {
      return res.data
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 猜你喜歡
 */
export const apiGetRecommend = () => {
  return $axios()
    .get(`:search/app/goods/sku/youLike`)
    .then((res: any) => {
      if (res.data) {
        return res.data
      } else {
        return []
      }
    })
    .catch(() => {
      return null
    })
}
