import { $axios_yx } from '../index'
import { nanoid } from 'nanoid'

export const imageUpload = (data: any) => {
  return $axios_yx()
    .post(`common/oss`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res: any) => {
      return res
    })
    .catch(() => {
      return null
    })
}

export const connectChatInfo = (visitorCode: string, data: object) => {
  const uuid = useLocalStorage('nk-chat', '')
  if (!uuid.value) {
    uuid.value = nanoid(12)
  }
  return $axios_yx()
    .post(`biz/visitor/handleVisitorInfo`, { visitorCode, visitorNonLoginCode: uuid.value, ...data })
    .then((res: any) => {
      if (res.code === 200) {
        return res.data
      } else {
        return null
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * 獲取訪客歷史消息
 * */
export const apiGetHistory = (pageNum = 1, pageSize = 20, visitorId: string) => {
  return $axios_yx()
    .formPost(`biz/visitor/clickVisitor`, { pageNum, pageSize, visitorId })
    .then((res: any) => {
      if (res.code === 200) {
        return res.data
      } else {
        return null
      }
    })
}

/**
 * 清楚未讀信息
 * */
export const apiClearUnRead = (visitorCode: string, time: string) => {
  return $axios_yx()
    .post(`biz/visitor/action/save`, {
      actionType: 3,
      actionContent: JSON.stringify({ text: '用戶閱讀了訊息' }),
      actionTime: time,
      visitorCode: visitorCode,
    })
    .then((res: any) => {
      if (res.code === 200) {
        return res.data
      } else {
        return null
      }
    })
}
