<template>
  <div class="item-box" :style="widgetStyle">
    <div class="pic-list picture" :style="widgetLayout">
      <div class="hotspot-pic-item" v-for="(item, index) in wData.items">
        <!-- 热区列表 -->
        <div class="hotspot-item" v-if="mode === 'Mask'">
          <div
            class="hotspot-area"
            v-for="(item2, index2) in item.hotspot"
            :style="`
            top:${item2.y.percent}%;
            left:${item2.x.percent}%;
            width:${item2.width.percent}%;
            height:${item2.height.percent}%
            `"
            @click="useContentAction(item2)"
          >
            <div>{{ t('render.hotspot.hotArea') }} {{ index2 + 1 }}</div>
            <div>{{ t('render.hotspot.action') }}：{{ item2.actionContent }}</div>
          </div>
        </div>
        <!-- 图片容器 -->
        <div class="hotspot-pic-content">
          <div class="hotspot-item-image">
            <img
              :id="`_${wData.id}`"
              :usemap="`#${wData.id}`"
              :src="item.serverPath ? item.serverPath : imageHolder"
              alt=""
              @load="loadImage"
            />
            <template v-if="buildMapArea">
              <map :name="wData.id" class="hot-area" v-if="mode === 'Area'">
                <area
                  shape="rect"
                  :coords="calcCoords(wData.id, item2)"
                  v-for="(item2, index2) in item.hotspot"
                  @click="useContentAction(item2)"
                />
              </map>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStyle, useShowMode } from './renderHooks'
const imageHolder: Ref<string> = ref('')
const { t } = useI18n()

const props = defineProps({
  wData: {
    type: Object,
    required: false,
    default: {},
  },
  // 1、Area 為原生的 map 標籤模式、2、Mask為使用 DOM 的遮罩模式
  mode: {
    type: String,
    required: false,
    default: 'Area',
    validator(value: string) {
      return ['Area', 'Mask'].includes(value)
    },
  },
})

// 是否構建熱區標籤 <map> <area>
const buildMapArea = ref(false)

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle

// 组件的布局
const widgetLayout: any = useShowMode(props.wData)

// 熱區點擊
// const onAreaClick = (data: any) => {
//   console.log('onAreaClick：', data.actionContent)
// }

// 計算熱區的 area 的座標
const calcCoords = (id: string, item2: any): string => {
  // 當前圖片的容器大小
  let imgDom: HTMLImageElement | null = document.querySelector(`#_${id}`)
  let imgWidth: number = imgDom?.getBoundingClientRect().width ?? 0
  let imgHeight: number = imgDom?.getBoundingClientRect().height ?? 0

  if (!imgDom) return ''

  // 數據傳入的，熱區的座標
  let { x, y, width, height } = item2

  let sourceWidth: number = +(width.pixel / (+width.percent / 100))
  let sourceHeight: number = +(height.pixel / (+height.percent / 100))
  // console.log("sourceWidth:", sourceWidth);
  // console.log("sourceHeight:", sourceHeight);

  let sX: number = +x.percent / 100 // 開始 x 坐標的百分比
  let sY: number = +y.percent / 100 // 開始 y 坐標的百分比
  let eX: number = (+x.pixel + +width.pixel) / sourceWidth // 結束 x 坐標的百分比
  let eY: number = (+y.pixel + +height.pixel) / sourceHeight // 結束 y 坐標的百分比

  // console.log("Coords:", `${sX*imgWidth},${sY*imgHeight},${eX*imgWidth},${eY*imgHeight}`);

  return `${sX * imgWidth},${sY * imgHeight},${eX * imgWidth},${eY * imgHeight}`
}

// 目前只支持單張圖片
const loadImage = (data: any) => {
  // console.log('loadImage:', data.target.id)
  buildMapArea.value = true
}

onMounted(() => {
  // console.log('props.wData:', props.wData)
})
</script>

<style scoped lang="scss">
.hotspot-pic-item {
  @include flex();
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;

  .hotspot-item {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;

    .hotspot-area {
      @include flex(column, center, center);
      position: absolute;
      padding: 10px;
      // background-color: rgba(255,255,255, .3);
      background-color: rgba(47, 84, 235, 0.3);
      border: 1px solid #8298f3;
      cursor: pointer;

      div {
        color: #fff;
        font-size: 12px;
      }
    }
  }

  .hotspot-pic-content {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // border: 1px solid #efefef;
    overflow: hidden;

    .hotspot-item-image {
      @include flex();
      @include cursor();

      img {
        display: inline-block;
        width: 100%;
        height: auto;
      }
      .hot-area {
        background-color: red;
      }
    }
  }

  .mask-tips {
    @include flex();
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.6);
    font-size: 20px;
    // font-weight: bold;
    color: #fff;
  }
}
</style>
