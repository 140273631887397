
// @ts-nocheck


export const localeCodes =  [
  "zh-MO",
  "zh-HK",
  "zh-CN",
  "en"
]

export const localeMessages = {
  "zh-MO": [{ key: "../assets/i18n/zh-HK.json", load: () => import("../assets/i18n/zh-HK.json" /* webpackChunkName: "locale__root_workspace_f2f_e3plus_newki_front_NY1p_assets_i18n_zh_HK_json" */), cache: true }],
  "zh-HK": [{ key: "../assets/i18n/zh-HK.json", load: () => import("../assets/i18n/zh-HK.json" /* webpackChunkName: "locale__root_workspace_f2f_e3plus_newki_front_NY1p_assets_i18n_zh_HK_json" */), cache: true }],
  "zh-CN": [{ key: "../assets/i18n/zh-CN.json", load: () => import("../assets/i18n/zh-CN.json" /* webpackChunkName: "locale__root_workspace_f2f_e3plus_newki_front_NY1p_assets_i18n_zh_CN_json" */), cache: true }],
  "en": [{ key: "../assets/i18n/en-US.json", load: () => import("../assets/i18n/en-US.json" /* webpackChunkName: "locale__root_workspace_f2f_e3plus_newki_front_NY1p_assets_i18n_en_US_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "zh-MO",
      "iso": "zh-MO",
      "files": [
        "assets/i18n/zh-HK.json"
      ]
    },
    {
      "code": "zh-HK",
      "iso": "zh-HK",
      "files": [
        "assets/i18n/zh-HK.json"
      ]
    },
    {
      "code": "zh-CN",
      "iso": "zh-CN",
      "files": [
        "assets/i18n/zh-CN.json"
      ]
    },
    {
      "code": "en",
      "iso": "en-US",
      "files": [
        "assets/i18n/en-US.json"
      ]
    }
  ],
  "defaultLocale": "zh-HK",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "assets/i18n",
  "rootRedirect": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "zh-MO",
      "iso": "zh-MO",
      "files": [
        {
          "path": "assets/i18n/zh-HK.json"
        }
      ]
    },
    {
      "code": "zh-HK",
      "iso": "zh-HK",
      "files": [
        {
          "path": "assets/i18n/zh-HK.json"
        }
      ]
    },
    {
      "code": "zh-CN",
      "iso": "zh-CN",
      "files": [
        {
          "path": "assets/i18n/zh-CN.json"
        }
      ]
    },
    {
      "code": "en",
      "iso": "en-US",
      "files": [
        {
          "path": "assets/i18n/en-US.json"
        }
      ]
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

