<template>
  <div class="newki-line">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 375 8" fill="none">
      <g clip-path="url(#clip68_5965)">
        <path
          id="path"
          d="M185.294 4C185.294 2.35291 186.164 0.882324 187.531 0L176.968 0L0 0L0 8L176.906 8L187.469 8C186.164 7.11768 185.294 5.64709 185.294 4Z"
          fill-rule="evenodd"
          fill="#00AEEB"
        />
        <path
          id="path"
          d="M190.327 0C188.028 0 186.164 1.82349 186.164 4C186.164 6.17651 188.028 8 190.327 8L375 8L375 0L190.327 0Z"
          fill-rule="evenodd"
          fill="#A9CD36"
        />
      </g>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.newki-line {
  width: 100%;
  height: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;

  svg {
    width: 100%;
    height: auto;
    background-color: $white;
  }
}
</style>
