<template>
  <div class="category-widget" :style="widgetStyle">
    <div class="list">
      <div class="item" v-for="item in 8" v-if="!categoryList.length">
        <div class="item-image">
          <!-- <img :src="HolderImage" width="64" height="64" /> -->
        </div>
        <div class="text-body-medium"></div>
      </div>
      <NuxtLink :to="goCategory(item.id, item.name)" class="item" v-for="item in categoryList" v-else>
        <div class="item-image">
          <NewTag class="new" v-if="item.newProductNum != '0'" />
          <div class="round-img">
            <img :src="useOSSImage(item.categoryImage)" onerror="this.style.display = 'none'" />
            <img class="holder" :src="HolderImage" width="64" height="64" />
            <!-- <van-image :width="64" :height="64" round block :lazy-load="true" :src="useOSSImage(item.categoryImage)" /> -->
            <!-- <NewkiImage
              :src="item.categoryImage"
              :width="64"
              :height="64"
              :round="true"
              :rules="{ newkiId: 'Category' }"
              :block="true"
              errorImage=""
            >
            </NewkiImage> -->
          </div>
        </div>
        <div class="text-body-medium">{{ item.categoryName }}</div>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import HolderImage from '~/assets/images/empty/normal.svg'
import NewTag from '~/components/render/NewTag.vue'
import { useStyle } from './renderHooks'
import { apiGetCategoryBySiteId } from '~/service/api/home'

const props = defineProps({
  wData: {
    type: Object,
    required: false,
    default: {},
  },
})

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle

const categoryList: any = ref([])

// 服務端調用
const { data } = await useAsyncData(`apiGetCategoryBySiteId`, () => apiGetCategoryBySiteId(), {
  lazy: true,
  default: () => ({
    categoryList: [],
  }),
})

watch(
  data,
  val => {
    if (val) {
      categoryList.value = val.list ?? []
    } else {
      categoryList.value = []
    }
  },
  { immediate: true }
)

const goCategory = (id: string, name: string) => {
  return routerNuxtLink('category', { query: { type: id, name: name, loadData: 1 } })
}
</script>

<style scoped lang="scss">
.category-widget {
  width: 100%;
  height: 100%;

  .list {
    display: grid;
    grid-template-columns: 64px 64px 64px 64px;
    justify-content: space-evenly;
    gap: 12px;

    .item {
      @include flex(column, flex-start, center);

      .item-image {
        position: relative;
        z-index: 10;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-color: $bg-color;
        // background-color: #e1e1e1;

        .new {
          position: absolute;
          top: 0px;
          right: -12px;
          z-index: 10;
        }

        img {
          display: block;
          width: 64px;
          height: 64px;
          border-radius: 50%;
          object-fit: contain;
        }

        .round-img {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          overflow: hidden;

          .holder {
            position: absolute;
            inset: 0;
            z-index: -10;
          }

          :deep(.van-image__img) {
            display: block;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            object-fit: contain;
          }
        }
      }

      .text-body-medium {
        margin-top: 4px;
        color: $text-color-default;
        background-color: rgba(0, 0, 0, 0);
        width: 100%;
        height: 22px;
        border-radius: 2px;
        text-align: center;
      }
    }
  }
}
</style>
