import type { ILogin } from '~/types/ILogin'
// import { useSetMemberInfo } from '~/composables/useSetMemberInfo'
import { getPlatForm } from '~/utils/getPlatForm'
import { setAppToken } from '~/utils/appMethods'
export const setLoginInfo = async (loginInfo: ILogin) => {
  useCookie('token', { maxAge: loginInfo.expiresIn || 7200 }).value = loginInfo.token
  // refresh_token過期時間一個月
  useCookie('refresh_token', { maxAge: 60 * 60 * 24 * 30 }).value = loginInfo.refreshToken || ''
  // 设置一个10分钟的登录状态，如果该cookie存在，设置密码不许要进行验证码校验
  useCookie('login_time', { maxAge: 600 }).value = 'true'
  // 登錄錯誤次數設為0
  useLocalStorage('login-error', 0)
  // await useSetMemberInfo()
  if (getPlatForm() === 'App') {
    setAppToken(loginInfo.token, loginInfo.refreshToken)
  }
}
