<template>
  <van-popup
    v-model:show="show"
    teleport="body"
    :round="isDialog"
    :duration="isDialog ? 0.3 : 0"
    position="bottom"
    :safe-area-inset-bottom="true"
    :close-on-click-overlay="maskClose"
    :style="{ height: isDialog ? '90%' : '100%', overflowY: 'unset' }"
    :lock-scroll="lockScroll"
  >
    <div v-if="isDialog" class="article-dialog-title">
      <div class="back" @click="back">
        <NewkiSvg name="chevron-left" :size="20" color="#000000" />
      </div>
      <div class="title">
        {{ title }}
      </div>
      <div class="right" @click="close">
        <NewkiSvg v-if="articleRouter.length > 1" name="cross" :size="20" color="#000000" />
      </div>
    </div>
    <NewkiNavBar v-else :title="title" :fixed="true" :show-line="true">
      <template #left>
        <div class="back" @click="back">
          <NewkiSvg name="chevron-left" :size="20" color="#000000" />
        </div>
      </template>
      <template #right v-if="articleRouter.length > 1">
        <NewkiSvg name="cross" :size="20" color="#000000" @click="close" />
      </template>
    </NewkiNavBar>
    <div class="article-content" v-if="articleList.length" :class="{ 'article-content-dialog': isDialog }">
      <Render v-for="(item, index) in articleList" :key="item.id" :name="item?.name" :wData="item" />
    </div>
    <NewkiEmpty v-else :recommend="false" :text="t('article.empty')" />
    <div class="article-bottom" v-if="isDialog" @click="close">
      {{ showSureBtn ? t('common.sure') : t('common.close') }}
    </div>
  </van-popup>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { apiGetArticle } from '~/service/api/article'
import { getPlatForm } from '~/utils/getPlatForm'
import type { IArticle } from '~/types/IArticle'
import NewkiNavBar from '~/components/NewkiNavBar.vue'
import Render from '~/components/render/Render.vue'
import { showFailToast } from 'vant'
import NewkiEmpty from '~/components/NewkiEmpty.vue'

const props = defineProps({
  code: {
    type: String,
    required: false,
    default: '',
  },
  scena: {
    type: Array,
    required: false,
    default: () => [],
  },
  isDialog: {
    type: Boolean,
    required: false,
    default: true,
  },
  showSureBtn: {
    type: Boolean,
    required: false,
    default: false,
  },
  maskClose: {
    type: Boolean,
    required: false,
    default: false,
  },
  lockScroll: {
    type: Boolean,
    required: false,
    default: false,
  },
})
const { t } = useI18n()
const show = ref(false)
const { $jsBridge }: any = useNuxtApp()
const article = ref<IArticle>({
  id: '',
  code: '',
  name: '',
  categoryId: '',
  siteId: '',
  sort: '',
  usageScena: '',
  content: '',
  status: '',
})
const articleList = ref<any[]>([])
const articleRouter = ref<any[]>([])
const emit = defineEmits(['error', 'sure'])

const title = ref(t('article.title'))

onMounted(() => {
  // @ts-ignore
  window.articleClick = articleClick
  // @ts-ignore
  window.externalLink = externalLink
})

async function init() {
  let data = null
  const router = articleRouter.value[articleRouter.value.length - 1]
  if (typeof router === 'string') {
    data = await apiGetArticle({ code: router })
    if (data && data[0]) {
      article.value = data[0]
      articleList.value = JSON.parse(data[0]?.content) || []
    } else {
      let _t = setTimeout(() => {
        showToast(t('article.empty'))
        clearTimeout(_t)
      }, 500)
    }
  } else if (typeof router === 'object') {
    data = await apiGetArticle({ usageScena: router })
    if (data) {
      const temp = data.sort((a: any, b: any) => +a.sort - +b.sort)
      article.value = temp[0]
      articleList.value = JSON.parse(data[0]?.content) || []
    }
  } else {
    showFailToast(t('article.error'))
    show.value = false
    if (!props.isDialog) {
      emit('error')
    }
  }
  if (articleList.value.length) {
    // 查找標題
    const index = articleList.value.findIndex(item => item?.name === 'page')
    if (index > -1) {
      title.value = articleList.value[index].title
      articleList.value.splice(index, 1)
    }
  }
}

async function open(val = []) {
  if (props.code && props.code !== '-1') {
    articleRouter.value.push(props.code)
  } else if (props.scena.length) {
    articleRouter.value.push(props.scena)
  }
  await init()
  show.value = true
}

function close() {
  clear()
  if (props.showSureBtn) {
    emit('sure')
  }
  if (!props.isDialog) {
    emit('error')
  }
  show.value = false
}

function back() {
  if (articleRouter.value.length === 1) {
    if (!props.isDialog) {
      emit('error')
      return false
    }
    show.value = false
    clear()
  } else {
    articleRouter.value.pop()
    init()
  }
}

function articleClick(e: any, id: string) {
  e.preventDefault()
  articleRouter.value.push(id)
  article.value = {
    id: '',
    code: '',
    name: '',
    categoryId: '',
    siteId: '',
    sort: '',
    usageScena: '',
    content: '',
    status: '',
  }
  articleList.value = []
  init()
}

function externalLink(e: any, link: string) {
  e.preventDefault()
  if (getPlatForm() === 'App') {
    $jsBridge.call('openInSystemBrowserSync', {
      url: link,
    })
  } else {
    window.open(link, '_blank')
  }
}

function clear() {
  article.value = {
    id: '',
    code: '',
    name: '',
    categoryId: '',
    siteId: '',
    sort: '',
    usageScena: '',
    content: '',
    status: '',
  }
  articleList.value = []
  articleRouter.value = []
}

defineExpose({
  open,
})
</script>

<style scoped lang="scss">
.back {
  @include cursor();
}

.article-dialog-title {
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $border-color;

  .back {
    @include cursor();
    position: relative;
    z-index: 10;
    display: flex;
  }

  .title {
    @extend .text-head-regular;
    color: $text-color-default;
  }

  .right {
    position: relative;
    z-index: 10;
  }
}
.article-content {
  @include scrollbar($width: 4px);
  padding-top: 54px;
  position: relative;
  height: 100%;
}
.article-content-dialog {
  height: calc(100% - 114px);
  padding-top: 10px;
}
.empty-container {
  height: calc(100% - 156px);
}
.article-bottom {
  @include cursor();
  @extend .text-body;
  border-top: 8px solid $bg-color;
  padding: 10px 0;
  text-align: center;
  color: $text-color-assist;
}
.empty-container {
  padding-top: 60px;
}
// :deep(.rich-text-render) {
//   pointer-events: none;
// }
</style>
