<template>
  <van-popup
    v-model:show="show"
    class="login-dialog-float login-dialog-widget"
    round
    teleport="body"
    :close-on-click-overlay="false"
    :lock-scroll="false"
  >
    <div class="agreement-container">
      <div class="text-content">
        <div>{{ t('login.use') }}</div>
        <div>
          {{ t('login.read')
          }}<span class="agreement-title" @click="openArticle('register')">{{ t('login.ServiceAgreement') }} </span
          ><span class="agreement-title" @click="openArticle('privacy')">{{ t('login.PrivacyAgreement') }}</span>
        </div>
      </div>
      <div class="btn-container">
        <van-button class="other-login-btn" round @click="cancel">
          {{ t('common.cancel') }}
        </van-button>
        <van-button class="other-login-btn login-btn" round type="primary" @click="submit">
          {{ t('common.ok') }}
        </van-button>
      </div>
      <NewkiLineSvg class="bottom-line" />
      <NewkiArticle v-if="scena.length" ref="articleRef" :scena="scena"></NewkiArticle>
    </div>
  </van-popup>
</template>

<script setup lang="ts">
import { Article } from '~/enum/article'
import NewkiArticle from '~/components/NewkiArticle.vue'
import NewkiLineSvg from '~/components/NewkiLineSvg.vue'

const { t } = useI18n()
const show = ref(false)
const scena = ref<any[]>([])
const articleRef = ref<InstanceType<typeof NewkiArticle> | null>(null)
const emit = defineEmits(['submit'])

function showModal() {
  show.value = true
}

function openArticle(type: string) {
  if (type === 'register') {
    scena.value = [Article.registerAgreement]
  } else {
    scena.value = [Article.privacyAgreement]
  }
  const timer = setTimeout(() => {
    clearTimeout(timer)
    articleRef.value?.open()
  })
}

function cancel() {
  show.value = false
}

function submit() {
  show.value = false
  emit('submit')
}

defineExpose({
  showModal,
})
</script>

<style scoped lang="scss">
:deep(.van-tabs__wrap) {
  display: none;
}
.login-dialog-float {
  width: 90%;
}
.agreement-container {
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .text-content {
    margin-left: 6px;
    color: $text-color-default;
    text-align: center;

    .agreement-title {
      color: $primary;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.btn-container {
  display: flex;
  margin: 20px 0;
  justify-content: center;
  gap: 20px;

  .other-login-btn {
    padding: 10px 40px;
  }
}
.login-btn {
  border-bottom: 4px solid $secondary;
}
.bottom-line {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
