import { $axios } from '../index'

/**
 * @description: 查詢帖文詳情
 */
export const getPostDetail = async (id: string, loading: true) => {
  loading && useLoading(true)
  return $axios()
    .get('/goods/app/postContent/postDetail' + `?id=${id}`)
    .then((res: any) => {
      loading && useLoading(false)
      if (res.success) {
        return res.data
      } else {
        return []
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 一級帖文評論分页查询
 */
export const queryByPostId = async (id: string, pager: any = { pageNum: 1, pageSize: 15 }) => {
  return $axios()
    .get(`/goods/app/postReview/queryByPostId?pageNum=${pager.pageNum}&pageSize=${pager.pageSize}&postId=${id}`)
    .then((res: any) => {
      if (res.success) {
        return res.data
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 二級帖文評論分页查询
 */
export const queryByOriginId = async (id: string, pager: any = { pageNum: 1, pageSize: 15 }) => {
  return $axios()
    .get(`/goods/app/postReview/queryByOriginId?pageNum=${pager.pageNum}&pageSize=${pager.pageSize}&originId=${id}`)
    .then((res: any) => {
      if (res.success) {
        return res.data
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 回覆帖文評論
 */
export const postReviewComment = async (data: any) => {
  return $axios()
    .post(`/goods/app/postReview/comment`, data)
    .then((res: any) => {
      if (res.success) {
        return res.data
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 評論點讚接口
 */
export const sendReviewLike = async (data: any) => {
  return $axios()
    .post(`/goods/app/reviewLike/update`, data)
    .then((res: any) => {
      if (res.success) {
        return res.data
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 根据贴文模版 id 获取贴文模版具体内容
 */
export const getPostTemplateContentById = async (id: string) => {
  return $axios()
    .get(`/goods/app/postTemplate/findById?id=${id}`)
    .then((res: any) => {
      if (res.success) {
        return res.data
      } else {
        return []
      }
    })
    .catch(() => {
      return null
    })
}
