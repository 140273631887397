// import { routerParams } from '@/types/router'
// import { routerPush } from '@/composables/routerPush'
import { ActionType } from '@/enum/actionType'
// import { IActionType } from '@/types/IActionType'
import { useSystemStore } from '~/store/system'
import { routerNuxtLink } from './routerNuxtLink'
import { ref, watch } from 'vue'
const log = console.log

export const useContentActionNuxtLink = (maybeRef: any) => {
  const data = toValue(maybeRef)
  const { updateChatDialog, updateChatPopup } = useSystemStore()
  const { actionCode, actionContent, actionContentCode } = data
  if (!actionCode) return ''
  // const actionData: IActionType = { actionCode, actionContent, actionContentCode }
  // 動作數據，來自用內容組件數據
  // log('actionCode:', actionCode)
  // log('actionContent:', actionContent)
  // log('actionContentCode:', actionContentCode)

  let url: string = ''
  const isLogin = ref(false) // 是否登錄

  watch(
    () => useIsLogin(),
    (val, oldVal) => {
      isLogin.value = !!val
    },
    { immediate: true, deep: true }
  )
  // 執行內容
  switch (actionCode!) {
    // 首頁
    case ActionType.INDEX:
      url = routerNuxtLink('')
      break

    // 購物車
    case ActionType.CART:
      url = routerNuxtLink('cart')
      break

    // 家品曆
    case ActionType.NEWKI:
      url = routerNuxtLink('newki-id', { params: { id: actionContentCode || -1 } })
      break

    case ActionType.MY:
      url = routerNuxtLink('my')
      break

    // 商品詳情頁
    case ActionType.PRODUCT_DETAIL:
      url = routerNuxtLink('product-skuId', { params: { skuId: actionContentCode } })
      break

    // 貼文詳情頁
    case ActionType.POST_DETAIL:
      url = routerNuxtLink('post-detail-id', { params: { id: actionContentCode } })
      break

    // // 文章詳情頁
    case ActionType.ARTICLE_DETAIL:
      url = routerNuxtLink('article-code', { params: { code: actionContentCode } })
      break

    // 外鏈跳轉
    case ActionType.EXTERNAL_LINK:
      url = actionContent
      // const target = actionContent.includes('newki') ? '_self' : '_blank'
      // window.open(actionContent, target, 'rel=noopener noreferrer')
      break

    //todo 介紹頁
    case ActionType.PROMOTION:
      url = routerNuxtLink('promotion-code', { params: { code: actionContentCode } })
      break

    //todo 活動頁
    case ActionType.ACTIVITY:
      url = routerNuxtLink('activity-code', { params: { code: actionContentCode } })
      break

    case ActionType.CHAT:
      url = routerNuxtLink('chat')
      break
    case ActionType.POPCHAT:
      // if (!isLogin.value) {
      //   updateChatDialog(true)
      // } else {
      //   updateChatPopup(true)
      // }
      url = ''
      break
    default:
      url = ''
  }
  return url
}
