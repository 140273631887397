<template>
  <div class="item-box" :style="widgetStyle">
    <!-- Slider main container -->
    <div class="swiper-widget" :class="swiperWidgetClass">
      <div class="swiper" :class="swiperClass">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper" :class="{ 'show-pagination': wData.showPagination }">
          <template v-for="item in wData.items">
            <div
              class="swiper-slide"
              :class="swiperSliderClass"
              @click="useContentAction(item)"
              v-if="item.actionCode === ActionType.POPCHAT"
            >
              <img :src="useOSSImage(item.serverPath, { dpr: 3, q: 100 })" alt="" />
            </div>
            <NuxtLink class="swiper-slide" :class="swiperSliderClass" :to="useContentActionNuxtLink(item)" v-else>
              <img :src="useOSSImage(item.serverPath, { dpr: 3, q: 100 })" alt="" onerror="this.style.display='none'" />
            </NuxtLink>
          </template>
        </div>
        <!-- If we need pagination -->
        <div class="swiper-pagination" v-if="wData.showPagination"></div>
        <!-- If we need navigation buttons -->
        <div
          :class="{ 'nav-button': wData.showPagination, disabled: isBeginning }"
          class="swiper-button-prev"
          v-if="option.navigation"
        >
          <div class="icon-left">
            <newki-svg name="goods-left" :filled="true" :size="16" />
          </div>
        </div>
        <div
          :class="{ 'nav-button': wData.showPagination, disabled: isEnd }"
          class="swiper-button-next"
          v-if="option.navigation"
        >
          <div class="icon-right">
            <newki-svg name="goods-right" :filled="true" :size="16" />
          </div>
        </div>
        <!-- If we need scrollbar -->
        <div class="swiper-scrollbar" v-if="option.scrollbar"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useStyle } from './renderHooks'
import { ActionType } from '@/enum/actionType'

// import { Swiper, SwiperSlide } from 'swiper/vue';
const { isMobile } = useDevice()
import Swiper from 'swiper'
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Mousewheel,
  EffectCube,
  EffectCoverflow,
} from 'swiper/modules'
// import type { SwiperModule } from 'swiper/types/shared.d.ts'

import 'swiper/css'
// import 'swiper/css/navigation';
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-cube'
import 'swiper/css/autoplay'
import 'swiper/css/effect-coverflow'

const emit = defineEmits(['onTypePicked', 'onSlideChange', 'onActiveIndexChange'])

// type StyleType = 'Single' | 'Center' | 'zIndex'

// Swiper 实例
const swiper = ref({})

const props = defineProps({
  name: {
    type: String,
    required: false,
    default: 'swiper',
  },
  option: {
    type: Object,
    required: false,
    default: {},
  },
  wData: {
    type: Object,
    required: false,
    default: {},
  },
})

// 当前激活的 index
const activeIndex: Ref<number> = ref(0)

// 是否第一个
const isBeginning: Ref<boolean> = ref(true)

// 是否最后一个
const isEnd: Ref<boolean> = ref(false)

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle

// swiper 类名
const swiperClass = ref({
  [props.name + (props.wData?.id ?? props.wData?.key)]: true,
})

// swiper-slide 类名
const swiperSliderClass = ref({
  center: props.wData.styleType === 'Center',
})

// swiper-widget 类名
const swiperWidgetClass = ref({
  coverflow: props.wData.styleType === 'zIndex',
  single: props.wData.styleType === 'Single',
  center: props.wData.styleType === 'Center',
})

// 初始化 swiper 选项
const buildOption = (wData: any = props.wData) => {
  return {
    navigation:
      wData?.items.length > 1 && !isMobile
        ? {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        : false,
    pagination: wData?.showPagination // If we need pagination
      ? {
          el: '.swiper-pagination',
        }
      : false,
    scrollbar: wData?.scrollbar // And if we need scrollbar
      ? {
          el: '.swiper-scrollbar',
        }
      : false,
    autoplay:
      Number(wData?.carouselDuration) > 0
        ? {
            delay: Number(wData?.carouselDuration) * 1000, // 時長
            disableOnInteraction: false, // 手動操作後停止西東播放
          }
        : false,
    effect: props.wData.styleType === 'zIndex' ? 'coverflow' : false,
    coverflowEffect: {
      depth: 80,
      modifier: 1,
      rotate: 0,
      scale: 0.95,
      slideShadows: false,
      stretch: isMobile ? 144 : 157,
    },
    // effect: "cube", // 执行条件 modules: [ EffectCube ]
    // cubeEffect: {
    //   slideShadows: false,
    // },
    on: {
      init: async (swiper: any) => {
        // console.log(`${props.name} Init Done`)
      },
      click: (swiper: any) => {
        emit('onTypePicked', swiper.clickedIndex)
      },
      slideChange: (swiper: any) => {
        emit('onSlideChange', swiper)
      },
      activeIndexChange: (swiper: any) => {
        emit('onActiveIndexChange', swiper)
        activeIndex.value = swiper.activeIndex
        isBeginning.value = swiper.isBeginning
        isEnd.value = swiper.isEnd
      },
    },
  }
}

const option: any = ref({
  loop: false,
  watchSlidesProgress: true,
  slidesPerView: 'auto',
  centeredSlides: true,
  preloadImages: false,
  modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay, Mousewheel, EffectCube, EffectCoverflow],
  ...buildOption(),
})

const initSwiper = () => {
  let id = props.wData?.id ?? props.wData?.key
  swiper.value = new Swiper(`.${props.name}${id}`, option.value)
}

const buildDotColor = () => {
  let id = props.wData?.id ?? props.wData?.key
  let dom: HTMLElement | null = document.querySelector(`.${props.name}${id}`)
  dom?.style.setProperty('--swiper-theme-color', props.wData.dotColor)
  dom?.style.setProperty('--swiper-pagination-color', props.wData.dotColor)
}

onMounted(() => {
  initSwiper()
  buildDotColor()
})
</script>

<style scoped lang="scss">
.item-box {
  background-size: cover;
  background-position: center center;
}

.swiper-widget {
  @include flex(column, flex-start, center);
  position: relative;
  z-index: 10;
  width: 100%;
  min-height: 100px;
  // padding-bottom: calc(45.6% + 20px);

  &.coverflow {
    // padding-bottom: calc(0);

    .swiper {
      $p-h: 20px;
      position: relative;
      z-index: 12;
      width: 100%;
      padding-bottom: $p-h;
      --swiper-navigation-size: 12px;

      .swiper-wrapper {
        width: 100%;
        // height: unset;

        &.show-pagination {
          padding-bottom: 0;
        }

        .swiper-slide {
          position: relative;
          width: 60%;
          // height: 0;
          // padding-bottom: calc(60% * 1.25);
          padding: 0;
          border-radius: 14px;
          // border: 2px solid $white;
          box-shadow: $shadow-md;
          background-color: $white;
          @include cursor();

          img {
            // position: absolute;
            // top: 0;
            // right: 0;
            // bottom: 0;
            // left: 0;
            // z-index: 10;
            display: block;
            object-fit: cover;
            object-position: center;
            border-radius: 14px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &.single {
    // padding-bottom: calc(60% + 20px);
  }

  &.center {
    // padding-bottom: calc(60% + 20px);
  }

  .swiper {
    // position: absolute;
    // top: 0;
    // right: 0;
    // left: 0;
    // bottom: 0;
    // z-index: 10;
    width: 100%;
    height: 100%;
    --swiper-theme-color: #aace37;
    --swiper-pagination-color: #aace37;
    --swiper-navigation-size: 12px;

    &:hover {
      :deep(.swiper-button-next),
      :deep(.swiper-button-prev) {
        &.nav-button {
          display: block;
          transition: opacity 0.2s ease;
        }
        &.nav-button.disabled {
          opacity: 0.3;
          cursor: default;
        }
      }
    }

    // 不支持悬停的设备如手机、平版电脑：隐藏按钮
    @media (hover: none) {
      :deep(.swiper-button-next),
      :deep(.swiper-button-prev) {
        &.nav-button {
          display: none;
        }
      }
    }

    .swiper-wrapper {
      align-items: center;

      &.show-pagination {
        padding-bottom: 20px;
      }

      .swiper-slide {
        width: 100%;
        border-radius: 14px;
        padding: 0 2.5%;
        @include cursor();

        &.center {
          width: 80%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 14px;
        }
      }
    }

    :deep(.swiper-pagination) {
      @include flex();
      width: 100%;
      height: 20px;
      bottom: 0;
    }

    :deep(.swiper-button-next) {
      right: 0;
      left: unset;
    }

    :deep(.swiper-button-prev) {
      left: 0;
      right: unset;
    }

    :deep(.swiper-button-next),
    :deep(.swiper-button-prev) {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 100;
      margin: auto 0;
      width: 32px;
      height: 32px;
      // padding-bottom: 9.6%;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.35);
      box-shadow: $shadow-md;

      &.nav-button {
        display: none;
        transform: translateY(-9px);
      }

      .icon-left,
      .icon-right {
        @include flex();
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        cursor: pointer;
      }

      &::after {
        display: none;
      }
    }
  }
}
</style>
