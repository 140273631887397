<template>
  <div class="top-navigation item-box" :class="{ isAffixed: wData.isAffixed }" :style="widgetStyle">
    <!-- <div class="navigation-box" :style="`background-color: ${wData.bgColor};`"> -->
    <div class="navigation-box">
      <div class="box-left">
        <!-- 返回 -->
        <div class="svg-icon arrow" :class="{ shadow: !wData.bgColor }" @click.stop="back">
          <NewkiSvg name="chevron-left" :size="20" :color="wData.textColor" />
        </div>
      </div>
      <div class="box-right">
        <!-- 加入购物车 -->
        <div class="svg-icon cart" :class="{ shadow: !wData.bgColor }" @click.stop="openCart" v-if="wData.showAddCart">
          <NewkiSvg name="nav-cart" :size="25" :color="wData.textColor" />
          <div class="dot">
            <span :style="`color: #ffffff;`">{{ cartNum }}</span>
          </div>
        </div>
        <!-- 分享 -->
        <div class="svg-icon share" :class="{ shadow: !wData.bgColor }" @click.stop="openShare" v-if="wData.showShare">
          <NewkiSvg name="share" :size="20" :color="wData.textColor" />
        </div>
      </div>
      <div class="placeholder-text" v-if="wData.showTitle">{{ wData.title }}</div>
    </div>
    <NewkiShare :share-content="shareContent" ref="shareRef" />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStyle } from './renderHooks'
import { useCartStore } from '~/store/cart'

const props = defineProps({
  wData: {
    type: Object,
    required: true,
  },
})

const { cartNum } = storeToRefs(useCartStore())

const emit = defineEmits(['topNaviBack', 'isMounted', 'openCart'])

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle

// 分享實例
const shareRef: any = ref(null)

// 分享文案
const shareContent = ref({})

// 打開分享
const openShare = () => {
  shareRef.value?.open()
}

// 打開購物車
const openCart = async () => {
  // if (props.wData.id === '_placeHolderId') return false
  emit('openCart', true)
}

// 回退按鈕
const back = () => {
  emit('topNaviBack')
}

onMounted(async () => {
  shareContent.value = {
    link: window.location.href,
  }
  await nextTick()
  emit('isMounted', { name: props.wData.name, isMounted: true, isAffixed: props.wData.isAffixed })
})
</script>

<style scoped lang="scss">
.top-navigation {
  @include flex();
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 13;
  width: 100%;
  transition: background 0.3s ease;

  &.isAffixed {
    position: fixed;
    // position: sticky;
  }

  .navigation-box {
    @include flex(row, space-between, center);
    width: 100%;
    height: 46px;
    padding: 10px;
    transition: background 0.2s ease;

    &.white {
      background-color: $white;
    }

    .box-left,
    .box-right {
      @include flex();
      z-index: 11;

      .svg-icon {
        @include flex();
        @include cursor();
        position: relative;
        z-index: 10;
        width: 32px;
        height: 32px;
        border-radius: 16px;

        // :deep(.icon) {
        //   margin: 0;
        // }

        &.arrow {
          &.shadow {
            background-color: rgba(0, 0, 0, 0.35);
          }

          :deep(.nuxt-icon) {
            position: relative;
            top: 0;
            right: 1px;

            // .icon {
            //   margin: 0;
            // }
          }
        }

        &.cart {
          margin-right: 6px;

          &.shadow {
            background-color: rgba(0, 0, 0, 0.35);
          }

          :deep(.nuxt-icon) {
            position: relative;
            top: 0px;
          }
        }

        &.share {
          &.shadow {
            background-color: rgba(0, 0, 0, 0.35);
          }

          :deep(.nuxt-icon) {
            position: relative;
            top: -1px;
            left: 2px;
          }
        }

        .dot {
          @include flex();
          position: absolute;
          top: -0px;
          right: -1px;
          z-index: 10;
          width: 14px;
          height: 14px;
          font-size: 12px;
          line-height: 1;
          background-color: $price;
          border-radius: 50%;

          span {
            @include flex();
            transform: scale(calc(8 / 12));
          }
        }
      }
    }

    .placeholder-text {
      @include flex();
      font-size: 15px;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
