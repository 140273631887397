<template>
  <van-form class="set-pwd-form" @submit="submit">
    <set-pwd-input @setPwd="setPwd" />
    <sms-input v-if="!needSMSValid" ref="smsInput" :is-valid-phone="true" :needAgreeAgreement="false" type="setPwd" />
    <div class="btn-container">
      <van-button class="login-btn" round block type="primary" native-type="submit">
        {{ t('common.sure') }}
      </van-button>
      <van-button class="other-login-btn" round block @click="skip">
        {{ t('common.skip') }}
      </van-button>
    </div>
  </van-form>
</template>

<script setup lang="ts">
import SetPwdInput from '~/components/login/SetPwdInput.vue'
import SmsInput from '~/components/login/SmsInput.vue'
import { apiSetPwd } from '~/service/api/login'

const props = defineProps({
  isDialog: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['cancel'])

const { t } = useI18n()
const needSMSValid = useCookie('login_time')
const smsInput = ref<InstanceType<typeof SmsInput> | null>(null)
let pwd = ''

const submit = async () => {
  let code: any = ''
  if (!needSMSValid.value) {
    code = smsInput.value?.smsCode
  }
  await apiSetPwd(pwd, code)
  if (props.isDialog) {
    emit('cancel')
  } else {
    routerBack()
  }
}

const setPwd = (val: any) => {
  pwd = val
}

const skip = () => {
  if (props.isDialog) {
    emit('cancel')
  } else {
    routerBack()
  }
}
</script>

<style scoped lang="scss">
.set-pwd-form {
  width: 100%;
  padding: 0 12px;
  box-sizing: border-box;

  .btn-container {
    margin-top: 32px;
    padding: 0 35px;

    .login-btn {
      border-bottom: 4px solid $secondary;
    }

    .other-login-btn {
      margin-top: 20px;
    }
  }
}
</style>
