export enum BtnType {
  normal, // 加入購物車
  addCart, // 加入購物車
  buyNow, // 立即購買
  sure, // 確認
  book, // 立即預約
  booked, // 已預約
  offState, // 商品已下架
  offStorage, // 地區無貨,請切換地址
  exceeds, // 地區無貨,請切換地址
}
