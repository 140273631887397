export const useCdnHost = (serverPath: string) => {
  let url: string = serverPath

  // const newkiCdnOrigin = useCookie('newki-cdn-origin').value

  let newkiCdnOrigin: any = ''

  if (isServer()) {
    newkiCdnOrigin = (global as any).newkiCdnOrigin
  } else {
    newkiCdnOrigin = useCookie('newki-cdn-origin').value
  }

  // const runtimeConfig = useRuntimeConfig()
  // const oss: string = runtimeConfig.public.oss
  // console.log('runtimeConfig:', runtimeConfig)

  // 1、如果是友现的地址，直接返回 （項目本地資源、或者拼接的地址）
  if (url.includes('ftofs-csim')) {
    return url
  }

  // 2、如果不是 aliyuncs OSS 的地址，直接返回 （項目本地資源、或者拼接的地址）
  if (!url.includes('aliyuncs')) {
    return url
  }

  if (newkiCdnOrigin) {
    const path = url.replace(/^.*?\.aliyuncs\.com/, '')
    url = `${newkiCdnOrigin}${path}`
    return url
  }

  return url
}
