import { $axios } from '../index'
/**
 * @description: 1、根據站點查詢首頁檔案信息
 */
export const getHomeContentBySiteId = async () => {
  return $axios()
    .get(':support/app/site/queryHomeContentBySiteId')
    .then((res: any) => {
      if (res.success) {
        return res.data
      } else {
        return []
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 根據站點查詢品類信息
 */
export const apiGetCategoryBySiteId = async () => {
  return $axios()
    .get(':support/app/site/queryCategoryBySiteId', {
      params: {
        pageNum: 1,
        pageSize: 0,
        timestamp: Date.now(),
      },
    })
    .then((res: any) => {
      if (res.success) {
        return res.data
      } else {
        return []
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 新品热品
 */
export const apiGetSkuNewHot = async () => {
  return $axios()
    .get(':search/app/goods/sku/newHot')
    .then((res: any) => {
      if (res.success) {
        return res.data
      } else {
        return []
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 根据站点查詢體驗分享
 */
export const getExperienceShare = async () => {
  return $axios()
    .get(':goods/app/postContent/experienceShare')
    .then((res: any) => {
      if (res.success) {
        return res.data
      } else {
        return []
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 根据站点查詢品味評測
 */
export const getTasteEvaluation = async () => {
  return $axios()
    .get(':goods/app/postContent/tasteEvaluation')
    .then((res: any) => {
      if (res.success) {
        return res.data
      } else {
        return []
      }
    })
    .catch(() => {
      return null
    })
}

/**
 * @description: 根据 skuId 查詢體驗分享
 */
export const getExperienceBySkuId = async (params: any) => {
  return $axios()
    .get(':goods/app/experienceSharing/findBySkuId', { params: params })
    .then((res: any) => {
      if (res.success) {
        return res.data
      } else {
        return []
      }
    })
    .catch(() => {
      return null
    })
}
