import revive_payload_client_kkRoEPyKRA from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/nuxt@3.9.0_@parcel+watcher@2.3.0_@types+node@18.19.3_encoding@0.1.13_eslint@8.56.0_opti_911a3121a2a3897a81157e93a28e05d5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_UejwqqBX7Q from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/nuxt@3.9.0_@parcel+watcher@2.3.0_@types+node@18.19.3_encoding@0.1.13_eslint@8.56.0_opti_911a3121a2a3897a81157e93a28e05d5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FTiHEf4MLf from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/nuxt@3.9.0_@parcel+watcher@2.3.0_@types+node@18.19.3_encoding@0.1.13_eslint@8.56.0_opti_911a3121a2a3897a81157e93a28e05d5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_bbCE3Hk5FD from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/nuxt@3.9.0_@parcel+watcher@2.3.0_@types+node@18.19.3_encoding@0.1.13_eslint@8.56.0_opti_911a3121a2a3897a81157e93a28e05d5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_Rk4GZJ8clb from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/nuxt@3.9.0_@parcel+watcher@2.3.0_@types+node@18.19.3_encoding@0.1.13_eslint@8.56.0_opti_911a3121a2a3897a81157e93a28e05d5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_SyXu8vNHno from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.9.1_typescript@5.8.3_vue@3.3.13_typescript@5.8.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/root/workspace/f2f-e3plus-newki-front_NY1p/.nuxt/components.plugin.mjs";
import prefetch_client_uLTUwvGmhT from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/nuxt@3.9.0_@parcel+watcher@2.3.0_@types+node@18.19.3_encoding@0.1.13_eslint@8.56.0_opti_911a3121a2a3897a81157e93a28e05d5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_djcSByVfc5 from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.9.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import composition_Dv3sqk511s from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@4.9.1_vue-router@4.2.5_vue@3.3.13_typescript@5.8.3___vue@3.3.13_typescript@5.8.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_tKZUg9AWtp from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@4.9.1_vue-router@4.2.5_vue@3.3.13_typescript@5.8.3___vue@3.3.13_typescript@5.8.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import vant_lazyload_plugin_W13KNvxip6 from "/root/workspace/f2f-e3plus-newki-front_NY1p/.nuxt/vant-lazyload.plugin.mjs";
import chunk_reload_client_E8yQu7O5Fw from "/root/workspace/f2f-e3plus-newki-front_NY1p/node_modules/.pnpm/nuxt@3.9.0_@parcel+watcher@2.3.0_@types+node@18.19.3_encoding@0.1.13_eslint@8.56.0_opti_911a3121a2a3897a81157e93a28e05d5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import analyze_client_MuxFcIkShO from "/root/workspace/f2f-e3plus-newki-front_NY1p/plugins/analyze.client.ts";
import error_ldt3PQauZ0 from "/root/workspace/f2f-e3plus-newki-front_NY1p/plugins/error.ts";
import i18n_client_gXssPVzz4q from "/root/workspace/f2f-e3plus-newki-front_NY1p/plugins/i18n.client.ts";
import im_client_8ex9Cq7J7y from "/root/workspace/f2f-e3plus-newki-front_NY1p/plugins/im.client.ts";
import jsBridge_client_Fg9jXwfMBP from "/root/workspace/f2f-e3plus-newki-front_NY1p/plugins/jsBridge.client.ts";
import route_client_8A0ENhmQRG from "/root/workspace/f2f-e3plus-newki-front_NY1p/plugins/route.client.ts";
export default [
  revive_payload_client_kkRoEPyKRA,
  unhead_UejwqqBX7Q,
  router_FTiHEf4MLf,
  payload_client_bbCE3Hk5FD,
  check_outdated_build_client_Rk4GZJ8clb,
  plugin_vue3_SyXu8vNHno,
  components_plugin_KR1HBZs4kY,
  prefetch_client_uLTUwvGmhT,
  plugin_djcSByVfc5,
  composition_Dv3sqk511s,
  i18n_tKZUg9AWtp,
  vant_lazyload_plugin_W13KNvxip6,
  chunk_reload_client_E8yQu7O5Fw,
  analyze_client_MuxFcIkShO,
  error_ldt3PQauZ0,
  i18n_client_gXssPVzz4q,
  im_client_8ex9Cq7J7y,
  jsBridge_client_Fg9jXwfMBP,
  route_client_8A0ENhmQRG
]