// 配合 usePageConfig 用来存储当前页面的配置数据、静态资源数据
export const usePageConfigStore = defineStore('pageConfig', () => {
  type PageConfigData = {
    id: string
    code: string
    frontendCode: string
    resources: any[]
    languageResources: any[]
  }
  // pageConfigData 数据
  const pageConfigData: Ref<PageConfigData> = ref({
    id: '',
    code: '',
    frontendCode: '',
    resources: [],
    languageResources: [],
  })

  // pageConfigData set
  function setPageConfigData(data: any) {
    pageConfigData.value = data
  }

  return {
    pageConfigData,
    setPageConfigData,
  }
})
