<template>
  <SectionCard :title="t('render.review.title')" type="success" text="review" :style="widgetStyle">
    <div class="review-widget">
      <NuxtLink :to="onOnlineClick()">
        <OneLine :text="onlineText" :avatar="onlineAvatar"></OneLine>
      </NuxtLink>
      <div class="review-box">
        <div class="lg-viewer">
          <div class="video-box">
            <div class="video-full">
              <NewkiVideo
                ref="NewkiVideoRef"
                :fitVideoSize="videoOption.fitVideoSize"
                :url="videoOption.url"
                :block="videoOption.block"
                :seekedStatus="videoOption.seekedStatus"
                :showMuteIcon="videoStatus === 'play'"
                :poster="videoOption.poster"
                :playerOptions="{
                  controls: true,
                }"
                @onVideoStatusChange="handleVideoStatusChange"
                v-if="videoOption.url"
              />
            </div>
          </div>
        </div>
        <div class="small-viewer">
          <div class="review-list">
            <div class="review-item" v-for="(item, index) in dataList" @click="onItemClick(index)">
              <div class="bg-image">
                <a :href="onOnlineUrl(item.id)" @click.prevent="onItemClick(index)">
                  <img :src="item.videoCoverPath" alt="" />
                </a>
              </div>
              <SkipLoop class="loop" :status="videoStatus" v-if="item.id === activeId" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </SectionCard>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue'
import OneLine from '~/components/render/OneLine.vue'
import SkipLoop from '~/components/SkipLoop.vue'
import NewkiVideo from '~/components/NewkiVideo.vue'
import SectionCard from '~/components/render/SectionCard.vue'
import { getTasteEvaluation } from '~/service/api/home'
const { t } = useI18n()

import { useStyle } from './renderHooks'

const props = defineProps({
  wData: {
    type: Object,
    required: true,
  },
})

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle

const onlineActiveId = ref('')

// 视频播放动画状态
const videoStatus = ref('')

const activeId = ref(0)

const videoOption = ref({
  url: '',
  seekedStatus: 'pause',
  block: true,
  fitVideoSize: 'fixWidth',
  poster: '',
})

const onlineText = ref('')
const onlineAvatar = ref('')

const dataList: any = ref([])

const NewkiVideoRef: any = ref(null)

const onItemClick = async (index: number, data: any = dataList.value) => {
  pick(data, index)
  await nextTick()
  NewkiVideoRef.value?.videoReload()
  NewkiVideoRef.value?.doMuted(false)
}

const onOnlineClick = () => {
  return routerNuxtLink('post-detail-id', {
    params: {
      id: onlineActiveId.value,
    },
  })
}

const onOnlineUrl = (id: any) => {
  return routerNuxtLink('post-detail-id', {
    params: {
      id: id,
    },
  })
}

const pick = (data: any, index: number) => {
  onlineActiveId.value = data && data[index]?.id
  activeId.value = data && data[index]?.id
  onlineText.value = data && data[index]?.text
  onlineAvatar.value = data && data[index]?.avatar
  videoOption.value.url = data && data[index]?.videoPath
  videoOption.value.poster = data && (data[index]?.videoCoverPath || data[index]?.videoPath)
}

const handleVideoStatusChange = (data: any) => {
  switch (data.eventName) {
    case 'play':
      videoStatus.value = 'play'
      break
    case 'pause':
      videoStatus.value = 'pause'
      break
    case 'ended':
      videoStatus.value = 'ended'
      break
    default:
      break
  }
}

// 服務端調用
const { data } = await useAsyncData(`getTasteEvaluation`, () => getTasteEvaluation(), {
  lazy: true,
  default: () => ({
    dataList: [],
  }),
})

watch(
  data,
  val => {
    if (val) {
      dataList.value = val
      pick(val, 0)
    }
  },
  { immediate: true }
)

onMounted(() => {
  // getData()
})
</script>

<style scoped lang="scss">
.review-widget {
  @include flex(column, flex-start, center);
  width: 100%;

  .review-box {
    width: 100%;
    margin-top: 20px;

    .lg-viewer {
      @include flex();
      width: 100%;

      .video-box {
        position: relative;
        width: calc(100% * 320 / 375);
        padding-bottom: calc(1.25 * 100% * 320 / 375);
        background-color: $bg-color;
        border-radius: 13px;

        .video-full {
          @include flex();
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 10;
          border-radius: 13px;
          overflow: hidden;

          :deep(.xgplayer) {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }

    .small-viewer {
      @include flex();
      margin-top: 15px;

      .review-list {
        @include flex(row, center, center);
        gap: 10px;
        width: 100%;
        padding: 0 calc(100% * 14 / (375 - 14 - 14));

        .review-item {
          position: relative;
          z-index: 10;
          width: calc(100% * 80 / (375 - 14 - 14));
          padding-bottom: calc(1.25 * 100% * 80 / (375 - 14 - 14));
          background-color: #e1e1e1;
          border-radius: 6px;

          .bg-image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
            border-radius: 6px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 6px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
</style>
