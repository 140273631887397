<template>
  <van-popup
    v-model:show="show"
    teleport="body"
    :round="isDialog"
    :duration="isDialog ? 0.3 : 0"
    position="bottom"
    :safe-area-inset-bottom="true"
    :close-on-click-overlay="maskClose"
    :style="{ height: isDialog ? '90%' : '100%', overflowY: 'unset', backgroundColor: '#f6f6f6' }"
    @close="close"
  >
    <ChatNavBar :is-dialog="isDialog" @close="close" @fullScreen="fullScreen" />
    <ChatBox v-if="flag && visitorCode" @close="close" />
    <div v-if="flag" class="bottom-container">
      <Toolbar @close="close" />
      <BottomToolbar />
    </div>
  </van-popup>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { apiClearUnRead } from '~/service/api/chat'
import { useMemberStore } from '~/store/member'
import { useImStore } from '~/store/im'
import BottomToolbar from '~/components/chat/BottomToolbar.vue'
import Toolbar from '~/components/chat/Toolbar.vue'
import ChatBox from '~/components/chat/ChatBox.vue'
import dayjs from 'dayjs'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  isDialog: {
    type: Boolean,
    required: false,
    default: true,
  },
  maskClose: {
    type: Boolean,
    required: false,
    default: false,
  },
})
const emit = defineEmits(['back', 'update:modelValue'])

const show = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const flag = ref(false)
const { visitorCode } = storeToRefs(useMemberStore())
const { setUnRead } = useImStore()

watch(
  () => show.value,
  val => {
    flag.value = val
  }
)

function open() {
  show.value = true
  flag.value = true
}

async function clearUnRead() {
  setUnRead(0)
  await apiClearUnRead(visitorCode.value, dayjs().format('YYYY-MM-DD HH:mm:ss'))
}

function close(name: string = '') {
  clearUnRead()
  if (!props.isDialog) {
    emit('back', name)
  } else {
    show.value = false
    if (name) {
      routerPush(name)
    }
  }
  flag.value = false
}

function fullScreen() {
  routerPush('chat')
  close()
}

defineExpose({
  open,
})
</script>

<style scoped lang="scss">
.nav-title {
  display: flex;
  align-items: center;
  .title {
    margin-left: 10px;
    position: relative;
    @include line(1);
    @extend .text-body;
    color: $text-color-default;
    z-index: 9;
  }
}
.bottom-container {
  position: fixed;
  bottom: 0;
  z-index: 11;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);
}
</style>
