<template>
  <div class="nav-bar-widget" :class="{ fixed: fixed }">
    <div class="nav-bar-container">
      <slot name="left">
        <div class="back" @click="back">
          <NewkiSvg name="chevron-left" :size="20" color="#000000" />
          <span v-if="props.leftText" class="left-text">{{ props.leftText }}</span>
        </div>
      </slot>
      <slot name="main">
        <span v-if="props.title" class="title text-head">{{ props.title }}</span>
      </slot>
      <slot name="right">
        <div class="right"></div>
      </slot>
    </div>
    <NewkiLineSvg v-if="showLine" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { getPlatForm } from '@/utils/getPlatForm'

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: '',
  },
  leftText: {
    type: String,
    required: false,
    default: '',
  },
  showLine: {
    type: Boolean,
    required: false,
    default: false,
  },
  fixed: {
    type: Boolean,
    required: false,
    default: false,
  },
})

// const isWechat = computed(() => {
//   return getPlatForm() === 'WeChat'
// })

function back() {
  routerBack()
}
</script>

<style scoped lang="scss">
.nav-bar-widget {
  width: 100%;
  height: 54px;
}
.nav-bar-container {
  background-color: $white;
  padding: 11px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    @include line(1);
    position: absolute;
    left: 40px;
    right: 40px;
    z-index: 9;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
  }

  .back {
    @include cursor();
    position: relative;
    z-index: 10;
    display: flex;

    .left-text {
      margin-left: 5px;
    }
  }

  .right {
    position: relative;
    z-index: 10;
  }
}
.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
</style>
