export const setAppToken = (paramsToken = '', paramsRefreshToken = '') => {
  const { $jsBridge }: any = useNuxtApp()
  const token = paramsToken || useCookie('token').value
  const refreshToken = paramsRefreshToken || useCookie('refresh_token').value
  const siteId = useCookie('newki-site').value
  $jsBridge.call('setToken', { token: token, refreshToken: refreshToken, siteId: siteId })
}
export const clearAppToken = () => {
  const { $jsBridge }: any = useNuxtApp()
  $jsBridge.call('clearToken', (res: any) => {})
}
