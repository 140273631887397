<template>
  <div class="nav-bar-container" :class="{ 'background-white': currentRoute === 'collect' }">
    <div class="nav-bar-content">
      <div class="nav-item" @click="goHome">
        <div class="icon-container" :class="{ 'icon-active': currentRoute.includes('index') }">
          <HomeFill class="active" :color="secondary.default" />
          <Home class="deactive" color="#ffffff" />
        </div>
        <span>{{ t('nav.home') }}</span>
      </div>
      <div class="nav-item" @click="goCategory">
        <div class="icon-container" :class="{ 'icon-active': currentRoute.includes('category') }">
          <LogFill class="active" :color="secondary.default" />
          <Log class="deactive" color="#ffffff" />
        </div>
        <span>{{ t('nav.category') }}</span>
      </div>
      <!-- :class="{ 'icon-active': currentRoute.includes('newki-id') }" -->
      <div class="nav-item newki-item" @click="goNewki">
        <div class="icon-container" :class="{ 'icon-active': currentRoute.includes('newki-id') }">
          <NavNewki class="active" />
          <NavNewkiSelected class="deactive" />
        </div>
        <span>{{ t('nav.newki') }}</span>
      </div>
      <div class="nav-item" @click="goCart">
        <div class="icon-container" :class="{ 'icon-active': currentRoute.includes('cart') }">
          <NavCartFill class="active" :color="secondary.default" />
          <NavCart class="deactive" color="#ffffff" />
          <van-badge>
            <template #content v-if="cartNum > 0">
              <span class="badge-num">{{ Number(cartNum) < 100 ? Number(cartNum) : '99+' }}</span>
            </template>
          </van-badge>
        </div>
        <span>{{ t('nav.shop') }}</span>
      </div>
      <div class="nav-item" @click="goMember">
        <div class="icon-container" :class="{ 'icon-active': currentRoute.includes('my') }">
          <MemberFill class="active" :color="secondary.default" />
          <Member class="deactive member-icon" color="#ffffff" />
        </div>
        <span>{{ t('nav.mine') }}</span>
      </div>
    </div>
    <div class="nav-nar-radius"></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useCartStore } from '~/store/cart'
import { useColorsStore } from '~/store/colors'
import HomeFill from '@/components/buttomNavSvg/HomeFill.vue'
import Home from '@/components/buttomNavSvg/Home.vue'
import Log from '@/components/buttomNavSvg/Log.vue'
import LogFill from '@/components/buttomNavSvg/LogFill.vue'
import NavNewki from '@/components/buttomNavSvg/NavNewki.vue'
import NavNewkiSelected from '@/components/buttomNavSvg/NavNewkiSelected.vue'
import NavCart from '@/components/buttomNavSvg/NavCart.vue'
import NavCartFill from '@/components/buttomNavSvg/NavCartFill.vue'
import Member from '@/components/buttomNavSvg/Member.vue'
import MemberFill from '@/components/buttomNavSvg/MemberFill.vue'

const { cartNum } = storeToRefs(useCartStore())

const { t } = useI18n()
const { secondary } = storeToRefs(useColorsStore())
const router = useRoute()

const currentRoute = computed(() => {
  const name = router.name?.toString()
  if (name) {
    const list = name.split('__')
    return list[list.length - 1]
  } else {
    return ''
  }
})

function goHome() {
  routerReplace('index')
}

function goNewki() {
  // routerReplace('newki-id', { params: { id: -1 } })
  routerReplace('newki-id')
}

function goCategory() {
  routerReplace('category', { query: { type: '-1', name: t('search.newGoods') } })
}

function goCart() {
  routerReplace('cart')
}

function goMember() {
  routerReplace('my')
}
</script>

<style scoped lang="scss">
// 一些开启了 layout：home 的页面不需要显示 BottomNav，在这里隐藏
.product-page ~ .nav-bar-container,
.promotion-page ~ .nav-bar-container {
  display: none;
}

.nav-bar-container {
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  padding-top: 46px;
  z-index: 999;
  background-color: transparent;
  overflow: hidden;

  .nav-bar-content {
    @include flex();
    gap: 6px;
    position: relative;
    top: -5px;
    z-index: 10;
    // padding: 0 10px;
    background-color: $primary;
    border-top-left-radius: 88px;
    border-top-right-radius: 88px;
    margin-top: -12px;

    .nav-item {
      @include flex(column, flex-end, center);
      @include cursor();
      position: relative;
      z-index: 10;
      height: 50px;
      color: $white;
      font-size: 12px;
      flex: 1;

      &.newki-item {
        width: 56px;
        .icon-container {
          position: absolute;
          top: -25px;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background-color: $primary;
          &.icon-active {
            background-color: $primary;
          }
          :deep(svg) {
            top: 0;
            transition: opacity 0.1s ease;
          }
        }
      }

      .icon-container {
        position: relative;
        z-index: 10;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 0.1s ease;

        &.icon-active {
          background-color: $white;

          .active {
            opacity: 1;
          }

          .deactive {
            opacity: 0.1;
          }
        }

        :deep(svg) {
          position: absolute;
          left: 0;
          top: 3px;
          right: 0;
          bottom: 0;
          z-index: 10;
          // transition: opacity 1s ease;
        }

        .deactive {
          opacity: 1;
        }

        .active {
          opacity: 0;
        }

        :deep(.van-badge) {
          position: absolute;
          top: 3px;
          right: 1px;
          z-index: 10;
          padding: 0 1px;

          .badge-num {
            padding: 0;
            display: block;
            font-size: 13px;
            transform: scale(calc(10 / 13));
            line-height: 1;
            padding: 0;
            border: none;
            user-select: none;
          }
        }
      }

      > span {
        margin-top: 4px;
        user-select: none;
      }
    }
  }
  .nav-nar-radius {
    position: absolute;
    z-index: -10;
    width: 160%;
    max-width: 768px;
    background-color: $primary;
    height: 128px;
    border-radius: 50%;
    left: -30%;
    top: 16px;
  }
}
.background-white {
  background-color: $white;
}
:deep(.nuxt-icon svg) {
  margin-bottom: 0;
}
</style>
