<template>
  <van-popup
    v-model:show="show"
    teleport="body"
    round
    position="bottom"
    :closeable="true"
    :safe-area-inset-bottom="true"
    :style="{ height: '90%', backgroundColor: bg }"
  >
    <div class="title">
      <img src="~/assets/images/common/logo.svg" width="76" height="24" />
    </div>
    <van-tabs
      class="goods-list-tab hide-scroll"
      :class="{ 'goods-list-tab-big': activeName === 'collect' || activeName === 'cart' }"
      v-model:active="activeName"
      :title-active-color="primary.default"
      line-width="60"
      line-height="1.5"
      @change="tabChange"
    >
      <van-tab :title="tabParams.history.title" name="history">
        <van-list
          v-model:loading="tabParams.history.loading"
          class="goods-list-container"
          :finished="tabParams.history.finished"
          :immediate-check="false"
          @load="getHistory"
          :finished-text="t('common.noMore')"
        >
          <NewkiEmpty
            v-if="!historyList.length"
            :text="hasSearch ? tabParams.history.empty : tabParams.history.searchEmpty"
            :recommend="false"
          />
          <template v-else>
            <div v-for="(item, index) in historyList" class="goods-container" :key="index">
              <NewkiGoodsItem
                :goods-name="item.goodsName"
                :goods-spec="item.saleAttrDesc"
                :img-src="item.image"
                :price="0"
                :retailPrice="0"
                :vipPrice="0"
                :img-size="92"
                :show-all-price="false"
              />
              <div class="text" @click="selected(item)">{{ t('common.select') }}</div>
            </div>
          </template>
        </van-list>
      </van-tab>
      <van-tab :title="tabParams.order.title" name="order">
        <van-list
          v-model:loading="tabParams.order.loading"
          class="goods-list-container"
          :finished="tabParams.order.finished"
          :immediate-check="false"
          @load="getOrder"
          :finished-text="t('common.noMore')"
        >
          <NewkiEmpty
            v-if="!orderList.length"
            :text="hasSearch ? tabParams.order.empty : tabParams.order.searchEmpty"
            :recommend="false"
          />
          <template v-else>
            <Card v-for="(item, index) in orderList" :key="index">
              <div class="goods-order-sn">
                <div class="order-sn-text">{{ t('orderDetail.order.no') }}：</div>
                <div class="order-sn-value">{{ item.billNo }}</div>
              </div>
              <NewkiGoodsItem
                v-for="(cItem, cIndex) in item.billGoodsVOList"
                :key="cItem.id"
                :goods-name="cItem.goodsName"
                :goods-spec="cItem.attr"
                :img-src="cItem.goodsImage"
                :price="0"
                :retailPrice="0"
                :vipPrice="0"
                :img-size="92"
                :show-all-price="false"
              />
              <div class="goods-order-select text" @click="selected(item)">
                {{ t('common.select') }}
              </div>
            </Card>
          </template>
        </van-list>
      </van-tab>
      <van-tab :title="tabParams.collect.title" name="collect">
        <van-list
          v-model:loading="tabParams.collect.loading"
          class="goods-list-container"
          :finished="tabParams.collect.finished"
          @load="getCollect"
          :finished-text="t('common.noMore')"
        >
          <NewkiEmpty v-if="!collectList.length" :text="tabParams.collect.empty" :recommend="false" />

          <template v-else>
            <div v-for="(item, index) in collectList" class="goods-container" :key="index">
              <NewkiGoodsItem
                :goods-name="item.goodsName"
                :goods-spec="item.saleAttrDesc"
                :img-src="item.image"
                :price="0"
                :retailPrice="0"
                :vipPrice="0"
                :img-size="92"
                :show-all-price="false"
              />
              <div class="text" @click="selected(item)">{{ t('common.select') }}</div>
            </div>
          </template>
        </van-list>
      </van-tab>
      <van-tab :title="tabParams.cart.title" name="cart">
        <van-list
          v-model:loading="tabParams.cart.loading"
          class="goods-list-container"
          :finished="tabParams.cart.finished"
          @load="getCart"
          :finished-text="t('common.noMore')"
        >
          <NewkiEmpty v-if="!cartList.length" :text="tabParams.cart.empty" :recommend="false" />

          <template v-else>
            <div v-for="(item, index) in cartList" class="goods-container" :key="index">
              <NewkiGoodsItem
                :goods-name="item.goodsName"
                :goods-spec="item.saleAttrDesc"
                :img-src="item.image"
                :price="0"
                :retailPrice="0"
                :vipPrice="0"
                :img-size="92"
                :show-all-price="false"
              />
              <div class="text" @click="selected(item)">{{ t('common.select') }}</div>
            </div>
          </template>
        </van-list>
      </van-tab>
      <template v-if="activeName === 'history' || activeName === 'order'" #nav-bottom>
        <div class="search-container">
          <van-form class="search-form" @submit="onSubmit">
            <van-field v-model="keyword" center :placeholder="tabParams[activeName].searchPlaceholder" :maxlength="30">
              <template #button>
                <div class="search-btn text-body" @click="onSubmit">{{ t('search.search') }}</div>
              </template>
            </van-field>
          </van-form>
        </div>
      </template>
    </van-tabs>
  </van-popup>
</template>

<script setup lang="ts">
import { apiGetHistory } from '~/service/api/common'
import { apiGetOrderList } from '~/service/api/order'
import { apiQueryCollect } from '~/service/api/collect'
import { apiQueryCart } from '~/service/api/cart'
import { useColorsStore } from '~/store/colors'

const emit = defineEmits(['selected'])
const { t } = useI18n()
const show = ref(false)
const activeName = ref('history')
const { bg, primary } = storeToRefs(useColorsStore())
const tabParams = ref({
  history: {
    title: t('my.history'),
    searchPlaceholder: t('search.goodsPlaceholder'),
    empty: t('search.historyEmpty'),
    searchEmpty: t('search.historySearchEmpty'),
    loading: false,
    finished: true,
    pageNum: 1,
  },
  order: {
    title: t('my.order.title'),
    searchPlaceholder: t('search.goodsOrderPlaceholder'),
    empty: t('search.orderEmpty'),
    searchEmpty: t('search.orderSearchEmpty'),
    loading: false,
    finished: true,
    pageNum: 1,
  },
  collect: {
    title: t('my.myCollect'),
    empty: t('search.collectEmpty'),
    loading: false,
    finished: false,
    pageNum: 1,
  },
  cart: {
    title: t('cart.cart'),
    searchPlaceholder: '',
    empty: t('search.cartEmpty'),
    loading: false,
    finished: false,
    pageNum: 1,
  },
})
const keyword = ref('')
const hasSearch = ref(false)
const historyList = ref<any[]>([])
const orderList = ref<any[]>([])
const collectList = ref<any[]>([])
const cartList = ref<any[]>([])

const open = (tabSelected = 'history') => {
  show.value = true
  activeName.value = tabSelected
  onSubmit()
}

function tabChange() {}

function onSubmit() {
  if (activeName.value === 'history') {
    historyList.value = []
    tabParams.value.history.pageNum = 1
    getHistory()
  } else if (activeName.value === 'order') {
    orderList.value = []
    tabParams.value.order.pageNum = 1
    getOrder()
  }
}

// 從瀏覽記錄查數據
async function getHistory() {
  let data = await apiGetHistory(tabParams.value.history.pageNum.toString(), keyword.value)
  if (data && data.list) {
    historyList.value.push(...data.list)
  } else {
    tabParams.value.history.finished = true
  }
  if (data) {
    tabParams.value.history.loading = false
    if (!data.hasNextPage) {
      tabParams.value.history.finished = true
    } else {
      tabParams.value.history.finished = false
      tabParams.value.history.pageNum++
    }
  }
}

// 從瀏覽記錄查數據
async function getOrder() {
  let data = await apiGetOrderList(tabParams.value.order.pageNum, { goodsName: keyword.value })
  if (data && data.list) {
    orderList.value.push(...data.list)
  } else {
    tabParams.value.order.finished = true
  }
  if (data) {
    tabParams.value.order.loading = false
    if (!data.hasNextPage) {
      tabParams.value.order.finished = true
    } else {
      tabParams.value.order.finished = false
      tabParams.value.order.pageNum++
    }
  }
}

// 從我的收藏查數據
async function getCollect() {
  let data = await apiQueryCollect(tabParams.value.collect.pageNum)
  if (data && data.list) {
    collectList.value.push(...data.list)
  } else {
    tabParams.value.collect.finished = true
  }
  if (data) {
    tabParams.value.collect.loading = false
    if (!data.hasNextPage) {
      tabParams.value.collect.finished = true
    } else {
      tabParams.value.collect.pageNum++
    }
  }
}

// 從我的購物車查數據
async function getCart() {
  let data = await apiQueryCart(tabParams.value.cart.pageNum)
  if (data && data.list) {
    cartList.value.push(...data.list)
  } else {
    tabParams.value.cart.finished = true
  }
  if (data) {
    tabParams.value.cart.loading = false
    if (!data.hasNextPage) {
      tabParams.value.cart.finished = true
    } else {
      tabParams.value.cart.pageNum++
    }
  }
}

// 將選擇的結果傳遞給父組件
function selected(data: any) {
  let list: any = []
  if (activeName.value === 'order') {
    list.push({
      billNo: data.billNo,
      billId: data.id,
      freightAmount: data.freightPayAmount,
      payableAmount: data.payableAmount,
      createTime: data.createTime,
      payTime: data.payTime,
      deliveryTypeDesc: data.deliveryTypeDesc,
      receiver: data.receiver,
      receiverTel: data.receiverTel,
      receiverAddress: data.receiverAddress,
      billStatusDesc: data.billStatusDesc,
      goodsList: data.billGoodsVOList.map((item: any) => {
        return {
          goodsId: item.goodsId,
          skuId: item.skuId,
          goodsName: item.goodsName,
          goodsImage: item.goodsImage,
          attr: item.attr,
          payPrice: item.payPrice,
          buyQty: item.buyQty,
          retailPrice: item.retailPrice || item.standardPrice,
        }
      }),
    })
  } else {
    list.push({
      goodsName: data.goodsName,
      saleAttrDesc: data.saleAttrDesc,
      image: data.image,
      goodsId: data.goodsId,
      skuId: data.skuId,
      memberPrice: data.memberPrice,
      retailPrice: data.retailPrice,
      supportPresell: data.supportPresell || '0',
    })
  }
  emit('selected', list, activeName.value)
  show.value = false
}

defineExpose({
  open,
})
</script>

<style scoped lang="scss">
.title {
  padding: 18px;
  background-color: $white;
  text-align: center;
}
.search-container {
  padding: 12px 20px;
  background-color: $white;

  .search-form {
    width: 100%;

    .van-cell {
      border: 1px solid $primary;
      padding: 3px 4px;
      border-radius: 99px;
    }

    :deep(.van-field__body) {
      padding-left: 12px;
    }

    .search-btn {
      padding: 3px 18px;
      background: $primary-gradual;
      border-radius: 99px;
      text-align: center;
      color: $white;
    }
  }
}

.goods-list-tab {
  height: calc(100% - 63px);
}
:deep(.van-tabs__content) {
  @include scrollbar($width: 3px);
  height: calc(100% - 106px);
}
.goods-list-tab-big {
  :deep(.van-tabs__content) {
    height: calc(100% - 46px);
  }
}
.goods-list-container {
  padding: 12px 8px;
}
.goods-container {
  display: flex;
  padding: 10px 18px;
  background-color: $white;
  border-radius: 12px;
  box-shadow: $shadow;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}
:deep(.goods-item-container) {
  flex-grow: 1;
  margin-bottom: 0;
}
.goods-order-sn {
  display: flex;
  margin-bottom: 10px;
  align-items: center;

  .order-sn-text {
    color: $text-color-default;
    line-height: 1;
  }
  .order-sn-value {
    color: $primary;
    line-height: 1;
  }
}
.goods-order-select {
  display: flex;
  justify-content: flex-end;
}
.text {
  flex-shrink: 0;
  @extend .text-body;
  color: $primary;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }
}
:deep(.card-container .goods-item-container) {
  margin-bottom: 6px;
}
</style>
