<template>
  <van-popup
    v-model:show="show"
    teleport="body"
    :overlay="false"
    position="top"
    :duration="0"
    :close-on-popstate="true"
    :close-on-click-overlay="false"
    :safe-area-inset-bottom="true"
    :style="{ height: '100%' }"
  >
    <!-- 40X -->
    <div class="error-container" v-if="String(code).includes('40')">
      <NewkiHeaderBar />
      <div class="error-content">
        <div class="error-img">
          <img :src="Newki404" alt="404" />
        </div>
        <div class="error-tip">
          <!-- <div class="text-bold">{{ code }}</div> -->
          <div class="text">{{ t('error.pageNotFount') }}</div>
          <div class="text">
            {{ t('error.pageJump') }}<span class="countdown">{{ timeCount }}</span
            >{{ t('error.pageJump1') }}
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
    <!-- 50X -->
    <div class="error-container" v-if="String(code).includes('50')">
      <div class="error-content">
        <div class="error-img">
          <img :src="Newki500" alt="500" />
        </div>
        <div class="error-tip code-500">
          <div class="text">{{ t('errorPage.updating') }}</div>
          <div class="text">{{ t('errorPage.tryLater') }}~</div>
          <div class="text">{{ t('errorPage.excuse') }}~</div>
        </div>
        <div class="refresh">
          <van-button class="login-btn" round block type="primary" @click="refresh">
            {{ t('errorPage.refresh') }}
          </van-button>
        </div>
      </div>
    </div>
    <!-- logo -->
    <div class="logo">
      <img :src="NewkiLogo" alt="logo" />
    </div>
  </van-popup>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import BottomNav from '~/components/BottomNav.vue'
import Newki404 from '~/assets/images/common/newki-404.png'
import Newki500 from '~/assets/images/common/newki-500.png'
import NewkiLogo from '~/assets/images/common/logo.svg'

const emit = defineEmits(['update:modelValue', 'refresh'])

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  code: {
    type: [String, Number],
    required: false,
    default: '404',
  },
})

const { t } = useI18n()

const timeCount = ref(3)

const show = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const timer: any = ref(null)

useHead({
  bodyAttrs: {
    id: 'pc-container',
  },
})

const clearTimer = (_timer: any = timer.value) => {
  console.log('NewkiErrorPage clearInterval')
  clearInterval(_timer)
}

const redirector = () => {
  if (!show.value) return false

  if (!import.meta.env.SSR && String(props.code).includes('40')) {
    timer.value = setInterval(() => {
      if (timeCount.value === 0) {
        const siteCode = useCookie('newki-site-code').value
        const siteId: any = useCookie('newki-site').value
        const languageMap = useCookie('newki-language').value
        let redirectUrl = '/'
        if (siteCode && siteId && languageMap && languageMap[siteId]) {
          redirectUrl = `/${siteCode}/${languageMap[siteId]}/`
        }
        clearError({ redirect: redirectUrl })
        clearTimer()
      } else {
        timeCount.value--
      }
    }, 1000)
  }
}

const refresh = () => {
  console.log('Refresh')
  clearTimer()
  emit('refresh')
  routerBack()
  let t = setTimeout(() => {
    clearTimeout(t)
    // 待优化
    routerRefresh()
  }, 300)
}

onMounted(() => {
  clearTimer()
  redirector()
})

onBeforeUnmount(() => {
  clearTimer()
})
</script>

<style scoped lang="scss">
.error-container {
  @include flex(column, flex-start, center);
  height: 100%;
  background-color: $white;
  position: relative;

  .error-content {
    @include flex(column, center, center);
    flex: 1;
    width: 100%;
    padding: 55px 0 168px;

    .error-img {
      position: relative;
      z-index: 10;
      $size: calc(58%);
      width: $size;
      padding-bottom: $size;

      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
      }
    }

    .error-tip {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .text-bold {
        font-size: 16px;
        font-weight: bold;
      }

      .text {
        color: $text-color-default;
        font-size: 15px;
      }

      &.code-500 {
        .text:nth-child(1) {
          font-size: 17px;
          margin-bottom: 10px;
        }
        .text:nth-child(2),
        .text:nth-child(3) {
          color: $text-color-assist;
        }
      }
    }
    .refresh {
      width: calc(190 / 375 * 100%);
      margin-top: 10px;
      .login-btn {
        border-bottom: 4px solid $secondary;
      }
    }
    .countdown {
      color: $price;
    }
  }
}

.logo {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
  height: auto;

  img {
    width: 100%;
    height: 40px;
  }
}
</style>
