// usePageConfig 用来获取并存储当前页面的配置数据、静态资源数据
import { ref } from 'vue'
import { apiGetPageContnt } from '~/service/api/common'
import { usePageConfigStore } from '@/store/pageConfig'

export const usePageConfig = async (pageCode: string = 'noticeBar') => {
  const { setPageConfigData } = usePageConfigStore()

  const configData: any = ref({})
  const getPageContent = async () => {
    const data = await apiGetPageContnt(pageCode)

    if (data) {
      configData.value = data
      setPageConfigData(data)
    } else {
      configData.value = {}
      setPageConfigData({})
    }
  }

  // steup 时立即获取数据
  getPageContent()

  return configData
}
