<template>
  <NuxtLink
    :to="useContentActionNuxtLink(contentAction)"
    v-if="isInRange && pageConfigData.frontendCode === 'noticeBar'"
    class="notice-bar"
    :style="styleObj"
    @click="onNoticeBarClick"
  >
    <span class="newki-logo">
      <NewkiSvg name="newki-logo" :size="16" :color="primary.default" />
    </span>
    <van-notice-bar mode="link" :color="primary.default" :background="primary.bg">
      <span class="text">{{ text }}</span>
    </van-notice-bar>
  </NuxtLink>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useColorsStore } from '~/store/colors'
import { usePageConfigStore } from '~/store/pageConfig'
import { apiGetServerTime } from '~/service/api/common'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

// 组件入参
const props = defineProps({
  // 页面代码
  pageCode: {
    type: String,
    required: false,
    default: 'noticeBar',
  },
  // 用于定位数据：pageConfigData.resources[sourceCode]
  sourceCode: {
    type: String,
    required: true,
    default: '',
  },
  mode: {
    type: String,
    required: false,
    default: 'link',
  },
  sticky: {
    type: Boolean,
    required: false,
    default: true,
  },
  top: {
    type: [Number, String],
    required: false,
    default: 0,
  },
  zIndex: {
    type: [Number, String],
    required: false,
    default: 100,
  },
})

// store 里面的页面资源数据
const { pageConfigData } = storeToRefs(usePageConfigStore())

// 需要显示的文字
const text = ref('')

// 时间区间
const datetimeRange: Ref<[string, string]> = ref(['', ''])

// 服務器時間
const serverTime: Ref<string> = ref('')

// 是否在时间区间内
const isInRange: Ref<boolean> = ref(false)

// 事件的动作类型
const contentAction: any = ref({
  actionCode: '',
  actionContent: '',
  actionContentCode: '',
})

const { primary } = storeToRefs(useColorsStore())

const styleObj: any = ref({
  position: 'relative',
  top: 'unset',
  zIndex: 0,
})

watchEffect(() => {
  text.value = pageConfigData.value.languageResources?.[0]?.content || ''
  checkTimeRange(pageConfigData.value)
  setContentAction(pageConfigData.value)
})

// 获取页面档案内容
usePageConfig(props.pageCode)

// 获取服務器時間
getServerTime()

async function getServerTime() {
  const time = await apiGetServerTime()
  const milliSeconds = String((time && time.timeMillis) || new Date().getTime())
  serverTime.value = dayjs(Number(milliSeconds)).format('YYYY-MM-DD HH:mm:ss')
}

function setContentAction(data: any = pageConfigData.value) {
  console.log('data:', data)
  let target = data.resources?.filter((item: any) => item.code === props.sourceCode)[0]
  if (!target) return
  // console.log('target：', target)

  let isArticle = target.executionContent.includes('article/')
  // console.log('isArticle：', isArticle)
  // console.log('target', target)

  // 使用 lastIndexOf 找到最后一个斜杠的位置
  const lastSlashIndex = target.executionContent.lastIndexOf('/')
  // 使用 substring 提取最后一个斜杠之后的部分
  const code = target.executionContent.substring(lastSlashIndex + 1)

  contentAction.value = {
    actionCode: isArticle ? 'F012' : target.actionType,
    actionContent: target.executionContent,
    actionContentCode: isArticle ? code : target.executionContentCode,
  }
}

function checkTimeRange(data: any = pageConfigData.value) {
  let target = data.resources?.filter((item: any) => item.code === props.sourceCode)[0]
  // console.log('target：', target)
  if (!target) return

  let range = target.content?.split(',') || ['', '']
  let startTime = ''
  let endTime = ''

  // 只有一个日期，取当日 24 小时内
  if (range.length === 1) {
    startTime = `${dayjs(range[0]).format('YYYY-MM-DD')} 00:00:00`
    endTime = `${dayjs(range[0]).format('YYYY-MM-DD')} 23:59:59`
    datetimeRange.value = [range[0], range[0]]
  }
  // 两个日期，取第一个和第二个
  if (range.length >= 2) {
    startTime = dayjs(range[0]).format('YYYY-MM-DD  HH:mm:ss')
    endTime = dayjs(range[1]).format('YYYY-MM-DD  HH:mm:ss')
    datetimeRange.value = [range[0], range[1]]
  }

  // 是否在合理的时间内
  let _isInRange = dayjs(serverTime.value).isBetween(startTime, endTime) || false
  isInRange.value = _isInRange
}

const onNoticeBarClick = () => {
  console.log('onNoticeBarClick')
}

onMounted(() => {
  if (props.sticky) {
    styleObj.value = {
      position: props.sticky ? 'sticky' : 'relative',
      top: `${props.top}px`,
      zIndex: props.zIndex,
    }
  }
  console.log('styleObj:', styleObj.value)
})
</script>

<style scoped lang="scss">
.notice-bar {
  display: block;
  position: relative;
  z-index: 10;
  width: 100%;
  height: 38px;
  padding-left: 26px;
  cursor: pointer;
  background-color: $primary-bg;

  .newki-logo {
    @include flex();
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    z-index: 10;
    // width: 40px;
    height: 100%;
  }

  .van-notice-bar {
    height: 100%;
  }

  :deep(.van-notice-bar) {
    font-size: 13px;
    padding: 0 10px 0 4px;
  }
}
</style>
