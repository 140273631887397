<template>
  <van-popup
    v-model:show="showPopUp"
    teleport="body"
    :close-on-click-overlay="false"
    :style="{
      transform: 'translateY(-64%)',
      overflowY: 'hidden',
    }"
  >
    <div class="close-icon" @click="close">
      <van-icon name="close" color="#ffffff" size="30" />
    </div>
    <div id="newkiActivityPopup" @click="action(popup)">
      <NewkiImage v-if="!popup.path?.includes('json')" height="360" width="375" :src="popup.path" :lazy-load="false" />
    </div>
  </van-popup>
  <LoginDialog ref="loginDialog" @callBack="loginSuccess" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { showFailToast } from 'vant'
import { apiGetPageContnt } from '~/service/api/common'
import { apiGetMemberInfo } from '@/service/api/member'
import { apiGetActivityDetail, apiGetActivityMemberInfo, apiActivityExchangeBatch } from '@/service/api/activity'
import { PopupFrequency } from '~/enum/popup'
import LoginDialog from '~/components/LoginDialog.vue'
import dayjs from 'dayjs'
import lottie from 'lottie-web'
import { useGetBetterPrice } from '~/store/getBetterPrice'
import { useActivityStore } from '~/store/activity'
const { setExchangeBatchStatus } = useActivityStore()

const { t } = useI18n()
const { showPopUp, getMoreFlag, pageCode } = storeToRefs(useGetBetterPrice())
const { setShowPopUp, setGetMoreFlag } = useGetBetterPrice()
const pagePopupList = ref<any[]>([]) // 應用頁面彈出列表
const finalAction = ref<any>({}) // 最后一个弹窗执行的动作
const imgDomain = useRuntimeConfig().public.oss
const popup = ref<any>({}) // 當前正在彈窗的數據
const loginDialog = ref<InstanceType<typeof LoginDialog> | null>(null)

let isLogin = ref(false)
let animation: any = null
let activityCode: any = '' // 活动代码
let activityId: any = '' // 活动id
let giftsIds: any = [] // 獎品兌換id
let usePage: any[] = [] // 应用页面
let frequency = '' // 弹出频率
let popupStorage = useLocalStorage('nk-activity-popup', '{}') // 彈窗緩存，用於判斷彈窗頻率
let activityFlag = false

const routeName = computed(() => {
  const route = useRoute()
  const name = route.name?.toString()
  const list = name.split('__')
  return list[list.length - 1]
})

const showErrorTip = computed(() => {
  return pageCode.value !== 'loginTriggerActivity'
})

watch(
  () => getMoreFlag.value,
  (val, oldVal) => {
    if (val && val !== oldVal) {
      giftsIds = []
      getMoreDiscount()
      setGetMoreFlag(false)
    }
  },
  { immediate: true }
)

watch(
  () => useIsLogin(),
  val => {
    isLogin.value = val
  },
  { immediate: true, deep: true }
)

// 获取页面档案内容
async function getMoreDiscount() {
  // if (pageCode.value === 'getBetterPrice') return false

  pagePopupList.value = []
  if (!activityFlag) {
    useLoading(true)
    activityFlag = true
    const data = await apiGetPageContnt(pageCode.value)
    if (data && data.resources && data.resources.length) {
      getActivityInfo(data.resources[0].executionContentCode)
    } else {
      useLoading(false)
      activityFlag = false
      showErrorTip.value && showFailToast(t('activity.end'))
    }
  }
}

// 获取活动档案内容
async function getActivityInfo(code: string) {
  if (!code) {
    activityFlag = false
    useLoading(false)
    return
  }
  const data = await apiGetActivityDetail(code)
  activityFlag = false
  useLoading(false)
  if (!data) {
    showErrorTip.value && showFailToast(t('activity.joined'))
    return
  }
  activityCode = data.code
  activityId = data.id
  data.activityGifts.forEach((item: any) => {
    if (item.id !== '0') {
      giftsIds.push(item.id)
    }
  })
  const popupPage = data.popupPage.split(',')
  popupPage.forEach((item: any) => {
    usePage.push(item)
  })
  frequency = data.popupFrequency
  if (!data.activityTemplates) return
  for (let i = 0; i < data.activityTemplates.length; i++) {
    if (data.activityTemplates[i].resourcesDetails) {
      pagePopupList.value.push({
        path: data.activityTemplates[i].resourcesDetails[0].filePath,
        code: data.activityTemplates[i].code,
      })
    }
    if (i === data.activityTemplates.length - 1 && data.activityTemplates[i].actionType) {
      finalAction.value = {
        code: data.activityTemplates[i].code,
        type: data.activityTemplates[i].actionType,
        content: data.activityTemplates[i].executionContentCode,
      }
    }
  }

  if (useIsLogin()) {
    // 如果已登录，删除未登录弹窗
    const index = pagePopupList.value.findIndex(item => item.code === '0001')
    if (index > -1) {
      pagePopupList.value.splice(index, 1)
    }
    loginSuccess()
  } else {
    pagePopup()
  }
}

function pagePopup() {
  const popupStorageMap = JSON.parse(popupStorage.value || '{}')
  if (pagePopupList.value.length) {
    const list = pagePopupList.value
    if (usePage && usePage.includes(routeName.value)) {
      // 满足应用页面
      if (frequency === PopupFrequency.day && popupStorageMap[activityCode]) {
        // 每天首次彈窗
        if (popupStorageMap[activityCode].time !== dayjs().format('YYYY-MM-DD')) {
          popupStorageMap[activityCode].hasShow = false
        }
      }
      if (frequency === PopupFrequency.ever && popupStorageMap[activityCode]) {
        popupStorageMap[activityCode].hasShow = false
      }
      if (!popupStorageMap[activityCode] || !popupStorageMap[activityCode].hasShow) {
        popup.value = list[0]
        setShowPopUp(true)
        if (list[0].path.includes('json')) {
          setTimeout(
            path => {
              showAnimation(imgDomain + path)
            },
            200,
            list[0].path
          )
        }
        pagePopupList.value.splice(0, 1)
      }
    }
  }
}

function showAnimation(path: string) {
  animation = lottie.loadAnimation({
    container: document.querySelector('#newkiActivityPopup')!,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: path,
  })
  animation.play()
}

function login() {
  loginDialog.value?.showModal()
}

async function loginSuccess() {
  const data = await apiGetActivityMemberInfo(activityId)
  if (data) {
    if ((+data.countLimit === 1 && +data.availableCount > 0) || +data.countLimit === 0) {
      // 有限制，且可兌換或者無限制
      exchangeGifts()
    } else {
      showErrorTip.value && showFailToast(t('activity.joined'))
    }
  } else {
    routerRefresh()
  }
}

function action(data: any) {
  close()
  switch (data.code) {
    case '0001':
      // 登錄
      login()
      break
    case '0002':
      // 點擊領取會員
      exchangeGifts()
      break
    case '0003':
      // 領取成功
      const params = {
        actionCode: finalAction.value.type,
        actionContentCode: finalAction.value.content,
        actionContent: finalAction.value.content,
      }
      close()
      useContentAction(params)
  }
}

function close() {
  animation && animation?.destroy()
  if (!pagePopupList.value.length) {
    let popupStorageMap = JSON.parse(popupStorage.value || '{}')
    if (!popupStorageMap[activityCode]) {
      popupStorageMap[activityCode] = {}
    }
    if (frequency === PopupFrequency.ever) {
      popupStorageMap[activityCode].hasShow = false
      popupStorageMap[activityCode].time = dayjs().format('YYYY-MM-DD')
    } else if (frequency === PopupFrequency.once) {
      popupStorageMap[activityCode].hasShow = true
      popupStorageMap[activityCode].time = dayjs().format('YYYY-MM-DD')
    } else if (frequency === PopupFrequency.day) {
      popupStorageMap[activityCode].hasShow = true
      popupStorageMap[activityCode].time = dayjs().format('YYYY-MM-DD')
    }
    popupStorage.value = JSON.stringify(popupStorageMap)
  }
  setShowPopUp(false)
}

async function exchangeGifts() {
  useLoading(true)
  const data = await apiActivityExchangeBatch(activityId, giftsIds)
  useLoading(false)
  if (data === 'success') {
    await apiGetMemberInfo()
    pagePopup()
    setExchangeBatchStatus(true)
  } else if (data === 'fail') {
    showErrorTip.value && showFailToast(t('activity.end'))
    setExchangeBatchStatus(false)
    return
  }
}
</script>

<style scoped lang="scss">
#newkiActivityPopup {
  width: 100%;
  height: 360px;
}
.close-icon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
}
.van-popup {
  margin: 0 auto;
  background: transparent;
  max-width: 100%;
}
</style>
